import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { Col, Grid, Row, styled, thd } from '@smooth-ui/core-sc';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

import { withSettings } from '../../containers/WebApp/SettingsContext';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import IntroBanner from 'components/CustomComponents/Banners/IntroBanner';
import BgBlack from 'components/CustomComponents/Backgrounds/BgBlack';

import CustomReactTableSimple from '_platform/src/components/CustomReactTable/CustomReactTableSimple';

import Diamond from './images/Icons-Diamond@2x.png';
import Bonus from './images/Icons-Bonus@2x.png';
import Core from './images/Icons-Core@2x.png';
import Gold from './images/Icons-Gold@2x.png';
import Platinum from './images/Icons-Platinum@2x.png';
import Ranging from './images/Icons-Ranging@2x.png';
import Silver from './images/Icons-Silver@2x.png';
import Spend from './images/Icons-Spend@2x.png';

const ProductsContainer = styled.div`
  a {
    color: #fff;
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  .products--link {
    text-decoration: none;
    color: #fff;
    background-color: ${thd('primary', '#000')};
    padding: 10px 50px 10px 50px;
  }
`;

const TierImage = styled.img`
  height: 60px;
  display: block;
  margin: 0 auto;
  margin-top: 10px;
`;

const HomePage = ({ settings }) => {
  const tierData = [
    {
      criteria: 'Monthly Core Range Target',
      diamond: '7 Tokens',
      platinum: '5 Tokens',
      gold: '3 Tokens',
      silver: '2 Tokens',
    },
    {
      criteria: 'Monthly Sales Target',
      diamond: '7 Tokens',
      platinum: '5 Tokens',
      gold: '3 Tokens',
      silver: '2 Tokens',
    },
    {
      criteria: 'Quarterly New Range Target*',
      diamond: '7 Tokens',
      platinum: '5 Tokens',
      gold: '3 Tokens',
      silver: '2 Tokens',
    },
  ];

  return (
    <div>
      <Helmet>
        {/* <meta name="description" content="Page description" /> */}
      </Helmet>

      <PrivateComponent
        redirectOnError={{
          pathname: settings.settingsApp.loginPagePath || '/login/',
        }}
      >
        <div>
          <IntroBanner
            pageTitle={
              <React.Fragment>
                Welcome to the 2025
                <br />
                <span className="text--primary">
                  Anchor Partners Plus Program
                </span>
              </React.Fragment>
            }
          />
        </div>
        <BgBlack>
          <Grid>
            <Row justifyContent="center">
              <Col md={8} className="text--center">
                <p>
                  We have taken on board your valued feedback and have made the
                  new-look program even simpler but without compromising the way
                  we reward distributors for their continued support of
                  Anchor&trade; Food Professionals products. The table and How
                  to Achieve section below outlines the various ways you can be
                  rewarded and what you will earn. Each month you will be
                  awarded Tokens based on your achievement against set criteria.
                  These Tokens can be redeemed for an increased range of
                  fantastic rewards.
                </p>
                <p>
                  Your Anchor FP Sales Representative will advise your targets
                  each quarter. You can track your earnings and progress on the
                  statement page.
                </p>
                <p>
                  Each distributor has been allocated a tier. The earning
                  potential of these tiers against set criteria each month is
                  detailed below. Talk to your Anchor FP Sales Representative
                  about how you can be promoted to the next tier.
                </p>

                <CustomReactTableSimple
                  showPagination={false}
                  sortable={false}
                  columns={[
                    {
                      Header: 'Criteria',
                      accessor: 'criteria',
                      style: { 'white-space': 'unset' },
                      minWidth: 200,
                    },
                    {
                      Header: (
                        <div>
                          Diamond
                          <TierImage src={Diamond} alt="" />
                        </div>
                      ),
                      accessor: 'diamond',
                      disableSortBy: true,
                    },
                    {
                      Header: (
                        <div>
                          Platinum
                          <TierImage src={Platinum} alt="" />
                        </div>
                      ),
                      accessor: 'platinum',
                    },
                    {
                      Header: (
                        <div>
                          Gold
                          <TierImage src={Gold} alt="" />
                        </div>
                      ),
                      accessor: 'gold',
                    },
                    {
                      Header: (
                        <div>
                          Silver
                          <TierImage src={Silver} alt="" />
                        </div>
                      ),
                      accessor: 'silver',
                    },
                  ]}
                  data={tierData}
                />
              </Col>
            </Row>
            <Row justifyContent="center" className="spacer">
              <Col className="text--center">
                <hr />
              </Col>
            </Row>
            <Row justifyContent="center" className="spacer">
              <Col sm={8} className="text--center">
                <h2>
                  How to <span className="text--primary">Achieve</span>
                </h2>
                <p>
                  <img src={Core} alt="" height={90} />
                </p>
                <h4>Core Product Ranging</h4>
                <p>
                  You will be set an individual number of products ranged target
                  at the beginning of each quarter. For each month you achieve
                  this target you will be awarded{' '}
                  <PrivateComponent
                    requiredRoles={['Diamond', 'Administrator']}
                  >
                    7 Tokens
                  </PrivateComponent>
                  <PrivateComponent
                    requiredRoles={[
                      'Platinum',
                      'AccountManager',
                      'Client',
                      'ClientRepresentative',
                      'DataAnalyst',
                      'Procurement',
                    ]}
                  >
                    5 Tokens
                  </PrivateComponent>
                  <PrivateComponent requiredRoles={['Gold']}>
                    3 Tokens
                  </PrivateComponent>
                  <PrivateComponent requiredRoles={['Silver']}>
                    2 Tokens
                  </PrivateComponent>
                  . Your baseline target will be refreshed each quarter.{' '}
                  <Link to="/products/">View products</Link>
                </p>

                <p className="spacer">
                  <img src={Spend} alt="" height={90} />
                </p>
                <h4>Invoice Spend</h4>
                <p>
                  You will be set an invoice spend target at the beginning of
                  each quarter. For each month you maintain or exceed this
                  target you will be awarded{' '}
                  <PrivateComponent
                    requiredRoles={['Diamond', 'Administrator']}
                  >
                    7 Tokens
                  </PrivateComponent>
                  <PrivateComponent
                    requiredRoles={[
                      'Platinum',
                      'AccountManager',
                      'Client',
                      'ClientRepresentative',
                      'DataAnalyst',
                      'Procurement',
                    ]}
                  >
                    5 Tokens
                  </PrivateComponent>
                  <PrivateComponent requiredRoles={['Gold']}>
                    3 Tokens
                  </PrivateComponent>
                  <PrivateComponent requiredRoles={['Silver']}>
                    2 Tokens
                  </PrivateComponent>
                  .
                </p>

                <p className="spacer">
                  <img src={Ranging} alt="" height={120} />
                </p>
                <h4>New Product Ranging</h4>
                <p>
                  Purchase the minimum quantities of an additional product that
                  is not part of your core range to be awarded{' '}
                  <PrivateComponent
                    requiredRoles={['Platinum', 'Administrator']}
                  >
                    7 Tokens
                  </PrivateComponent>
                  <PrivateComponent
                    requiredRoles={[
                      'Diamond',
                      'AccountManager',
                      'Client',
                      'ClientRepresentative',
                      'DataAnalyst',
                      'Procurement',
                    ]}
                  >
                    5 Tokens
                  </PrivateComponent>
                  <PrivateComponent requiredRoles={['Gold']}>
                    3 Tokens
                  </PrivateComponent>
                  <PrivateComponent requiredRoles={['Silver']}>
                    2 Tokens
                  </PrivateComponent>{' '}
                  for that product. <Link to="/products/">View products</Link>
                </p>

                <p>
                  * Tokens for this criteria are earned for each new product
                  ranged at the following minimum quantities. If you achieve the
                  minimum quantity within the first month of the quarter, the
                  tokens will be awarded in the following month.
                </p>

                <PrivateComponent requiredRoles={['Diamond', 'Administrator']}>
                  <p>
                    Diamond Tier - Maintain Core Product Ranging to be awarded 7
                    Tokens
                  </p>
                </PrivateComponent>
                <PrivateComponent
                  requiredRoles={[
                    'Platinum',
                    'AccountManager',
                    'Client',
                    'ClientRepresentative',
                    'DataAnalyst',
                    'Procurement',
                  ]}
                >
                  <p>
                    Platinum Tier - minimum of 1 pallet to quality for new
                    product ranging achievement
                  </p>
                </PrivateComponent>
                <PrivateComponent requiredRoles={['Gold']}>
                  <p>
                    Gold Tier - minimum of 1 layer to quality for new product
                    ranging achievement
                  </p>
                </PrivateComponent>
                <PrivateComponent requiredRoles={['Silver']}>
                  <p>
                    Silver Tier - minimum of 1 layer to quality for new product
                    ranging achievement
                  </p>
                </PrivateComponent>

                <p className="spacer">
                  <img src={Bonus} alt="" height={120} />
                </p>
                <h4>Bonus Opportunity</h4>
                <p>
                  Look out for opportunities to earn additional tokens. This may
                  include new product launches, buy show volume targets or
                  focused category opportunities.
                </p>
              </Col>
            </Row>
            <Row justifyContent="center" className="spacer">
              <Col className="text--center">
                <hr />
              </Col>
            </Row>
            <Row justifyContent="center" className="spacer">
              <Col sm={8} className="text--center">
                <ProductsContainer>
                  <h2>
                    Participating{' '}
                    <span className="text--primary">Products</span>
                  </h2>
                  <p className="spacer--small--bottom">
                    The range of core products from each priority category can
                    be <Link to="/products/">viewed here</Link>.
                    <br />
                    The full range of{' '}
                    <strong>
                      Anchor<sup>™</sup> Food Professionals
                    </strong>{' '}
                    products can be seen on{' '}
                    <a
                      href="https://www.anchorfoodprofessionals.com/au/en/our-products.html"
                      target="_blank"
                      title="Anchor Food Professionals"
                      rel="noopener noreferrer"
                    >
                      our website
                    </a>
                    .
                  </p>
                  <p>
                    <Link to="/products/" className="products--link ">
                      View Participating Products
                    </Link>{' '}
                  </p>
                </ProductsContainer>
              </Col>
            </Row>
          </Grid>
        </BgBlack>
      </PrivateComponent>
    </div>
  );
};

HomePage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withSettings(HomePage);
