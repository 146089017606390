import { styled, thd } from '@smooth-ui/core-sc';
import CustomReactTable from '_platform/src/components/CustomReactTable/CustomReactTable';

const CustomReactTableSimple = styled(CustomReactTable)`
  &.ReactTable {
    border: 0 none;

    .rt-thead.-header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      box-shadow: none;
    }

    .rt-thead .rt-resizable-header-content {
      white-space: normal;
      overflow: initial;
      text-overflow: initial;
    }

    .-pagination {
      border-top-width: 1px;
      box-shadow: none;

      .-btn {
        border-radius: ${thd('borderRadius', '0')};
      }
    }
  }
`;

export default CustomReactTableSimple;
