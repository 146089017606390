import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { styled, thd } from '@smooth-ui/core-sc';

export const StyledCustomReactTable = styled(ReactTable)`
  /* Move resizer within the Header, to prevent focus ring breaking container */
  .rt-table .rt-resizer {
    right: 0;
    z-index: auto;
  }

  && .rt-thead.-header {
    background-color: rgba(0, 0, 0, 0.025);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: none;
    white-space: normal;
  }

  .rt-thead .rt-resizable-header-content {
    white-space: normal;
    overflow: initial;
    text-overflow: initial;
  }

  .rt-th {
    align-self: center; /* Vertically align cells */
    font-weight: bold;
  }

  .rt-tr.-odd {
    background-color: rgba(0, 0, 0, 0.0125);
  }

  .rt-row-selected,
  .ReactTable.-highlight .rt-tbody .rt-tr.rt-row-selected:not(.-padRow):hover {
    background-color: ${thd(
      'primaryLight',
      'rgba(0, 50, 100, .125)'
    )} !important;
  }

  && .-pagination {
    border-top-width: 1px;
    box-shadow: none;
  }
`;

export const defaultOptions = {
  defaultPageSize: 100,
  minRows: 1,
  pageSizeOptions: [50, 100, 250, 500],
};

export const numberFilterMethod = (filter, row) => {
  if (filter.value === '0') {
    return row[filter.id] === 0;
  }

  const filterValue = Number(filter.value.replace(/,/g, '').trim());

  return row[filter.id] === filterValue;
};

const CustomReactTable = ({ ...props }) => (
  <StyledCustomReactTable {...defaultOptions} {...props} />
);

export default CustomReactTable;
